import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/UI/PageContents';

const OurServices = () => (
  <Layout headerText="Our Services">
    <SEO title="Our Services" />
    <PageContent>
      <h2 className="h4">All kind of events</h2>
      <p>We provide full services related to any event.</p>
      <p className="mt-4">Our services include, but are not limited to:</p>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-lg-8">
          <ul>
            <li>Event planning</li>
            <li>Entertainment events</li>
            <li>Wedding planning</li>
            <li>Birthday and all other parties</li>
            <li>Corporate events</li>
            <li>Press meets</li>
            <li>Product launches</li>
            <li>Music and song launches</li>
            <li>Movie launch events</li>
            <li>Drone photography</li>
            <li>Light and sound shows</li>
            <li>Advertisement events</li>
            <li>All other event planning and event co-ordinations</li>
          </ul>
        </div>
        <div className="col-12 col-lg-4"></div>
      </div>
    </PageContent>
  </Layout>
);

export default OurServices;
